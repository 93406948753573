.contact2 {
    height: 60vh;
    color: #404040;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.contact2 .container {
    width: 100%;
    max-width: 1000px;
    height: auto;
    text-align: center;
    background-color: #e2dcd4;
    color: #404040;
}

.contact2 h1,
.contact2 h2,
.contact2 h3,
.contact2 h4,
.contact2 h5,
.contact2 h6 {
    font-size: 30px;
    font-weight: 400;
    color: #404040;
    text-align: center;
}

form {
    text-align: center;
}

.box {
    border-radius: 0;
    margin: 0!important;
    padding: 0!important;
    background-color: #e2dcd4;
}

.contact2 .social-icons {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.contact2 .social-icons a:hover {
    background-color: #e2dcd4;
    color: #404040;
}

.contact2 .social-icons a {
    background-color: #404040;
    color: #e2dcd4;
}

.contact2 .contact-container .links a {
    color: #404040;
}

.contact2 .contact-container {
    position: relative;
}

.contact2 .btn {
    background: linear-gradient(to bottom right, #e2dcd4, #d6ccc2);
    border: 1px solid #404040;
    border-radius: 12px;
    color: #404040;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 2.5;
    outline: transparent;
    padding: 0 1rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
}

.contact2 .btn:not([disabled]):focus {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(214, 204, 194, 0.5), .125rem .125rem 1rem rgba(40,40,40, 0.5);
}

.contact2 .btn:not([disabled]):hover {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(214, 204, 194, 0.5), .125rem .125rem 1rem rgba(40,40,40, 0.5);
}

.detail-box {
    text-align: left;
    height: auto;
}

.detail {
    margin: 0!important;
}

/* Медиа-запросы для адаптивного дизайна */
@media (max-width: 768px) {
    .contact2 {
        height: 300px;
    }

    .contact2 .container {
        width:300px;
        height: auto;
        padding: 0;
    }

    .contact2 .contact-container {
        flex-direction: column;
        align-items: center;
    }

    .contact2 .contact-container .row {
        flex-direction: column;
        align-items: center;
    }

    .box,
    .contact-box,
    .detail-box {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
    }

    .detail-box {
        height: auto;
        text-align: center;
    }

    .contact2 .social-icons {
        flex-wrap: wrap;
        justify-content: center;
    }

    .contact2 .social-icons a {
        margin: 5px;
    }

    /* Убираем .detail на мобильных устройствах */
    .detail {
        display: none;
    }

    /* Уменьшаем размеры контактной формы и её элементов */
    .contact-box {
        padding: 10px;
    }

    .contact2 .form-control {
        font-size: 14px;
        padding: 5px;
    }

    .contact2 .btn {
        font-size: 14px;
        padding: 5px 10px;
        line-height: 2;
    }

    .contact2 h1,
    .contact2 h2,
    .contact2 h3,
    .contact2 h4,
    .contact2 h5,
    .contact2 h6 {
        font-size: 20px;
    }
}
