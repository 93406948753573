/* Общие стили */
.great {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    font-family: system-ui;
}


.great .title{
    margin-top: 100px;
    text-align: center;
    font-size: 40px;
    padding: 0!important;
    font-weight: bold;
    letter-spacing: 2px;
    background: linear-gradient(20deg, #DAA520, black, #DAA520, #DAA520, #DAA520);
    -webkit-background-clip: text;
    color: transparent;
    background-size: 200%;
}
/* Обертка */
.wrapper {
    padding: 0px 5% 50px 5%;
}

/* Область карточек */
#card-area {
    padding: 0;
}

.box-area {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
    margin-top: 0px;
}

/* Карточки */
.box {
    border-radius: 10px;
    background-color: #e2dcd4;
    text-align: center;
    position: relative;
    transition: transform 0.3s;
}

/*.box:hover {*/
/*    transform: scale(1.05);*/
/*}*/

.box:hover .gradient-border {
    font-size: 175px;
    background: linear-gradient(217deg, black, #ffd700 70.71%),
    linear-gradient(127deg, rgba(0, 255, 0, 0.8), #ffd700 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), #ffd700 70.71%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-stroke: 4px transparent;
    color: black;
}

.gradient-border {
    font-family: system-ui;
    font-size: 175px;
    background: linear-gradient(217deg, black, #ffd700 70.71%),
    linear-gradient(127deg, rgba(0, 255, 0, 0.8), #ffd700 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), #ffd700 70.71%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-stroke: 2px transparent;
    color: #404040;
}

.box .value {
    font-family: 'Playfair Display', serif;
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
}

.box:hover .value {
    font-weight: bold;
}

/* Описание */
.description {
    border: 1px solid transparent;
    border-image: linear-gradient(to right, black, #ffd700, black, #ffd700, black) 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 90%;
    max-width: 1100px;
    font-weight: 400;
    height: 100px;
    background-color: #e2dcd4;
    color: #404040;
    font-size: 20px;
    padding: 30px;
    border-radius: 10px;
    font-family: 'Playfair Display', serif;
    text-align: center;
}

/* Адаптивные стили */
@media (max-width: 768px) {
    .box-area {
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
        gap: 5px;
    }
    .great .title{
        font-size: 30px;
    }

    .gradient-border {
        font-size: 125px;
    }

    .description {
        font-size: 18px;
        height: auto;
        padding: 20px;
    }
    .box .value {
        font-weight: bold;
        font-size: 12px;
    }
    .box:hover .gradient-border {
        font-size: 125px;
    }
}

@media (max-width: 480px) {
    .great .title{
        font-size: 25px;
    }
    .wrapper {
        padding: 0px 5% 50px 5%;
    }
    .great {
        margin: 100px auto;
    }

    .box-area {
        grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
        gap: 5px;
    }

    .gradient-border {
        font-size: 50px;
    }

    .description {
        font-size: 8px;
        padding: 15px;
    }
    .box .value {
        font-size: 8px;
    }
    .box:hover .gradient-border {
        font-size: 50px;
    }
}
