.how-we-work{
    padding-top: 200px;
}

.how-we-work .title h1 {
    text-align: center;
    font-size: 40px;
    padding: 0!important;
    font-weight: bold;
    letter-spacing: 2px;
    background: linear-gradient(20deg, #DAA520, black, #DAA520, #DAA520, #DAA520);
    -webkit-background-clip: text;
    color: transparent;
    background-size: 200%;
}

.hww_container {
    opacity: 0;
    transition: opacity 1s, transform 1s;
    transform: translateY(-30px);
}

.hww_container.animate {
    opacity: 1;
    transform: translateY(0);
}

.hww_container h2 {
    font-size: 20px;
    color: #e2dcd4;
    text-align: left;
}

.timeline {
    position: relative;
    max-width: 1200px;
    margin: 100px auto;
}

.hww_container {
    z-index: 10;
    contain: none;
    padding: 10px 50px;
    position: relative;
    width: 50%;
}

.text-box {
    color: goldenrod;
    padding: 20px 30px;
    background: #404040;
    position: relative;
    border-radius: 6px;
    font-size: 15px;
}

.left-container {
    left: 0;
}

.right-container {
    left: 50%;
}

.hww_container img {
    position: absolute;
    width: 30px;
    border-radius: 50%;
    right: -15px;
    top: 37px;
    z-index: 1;
    transform: translateZ(0);
}

.right-container img {
    left: -15px;
}

.left-container .last-img {
    top: 90px;
    width: 60px;
    right: -30px;
    transform: rotate(40deg);
}

.timeline::after {
    content: "";
    position: absolute;
    width: 6px;
    max-height: 92%;
    background: #404040;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    animation: moveline 8s linear forwards;
}

@keyframes moveline {
    0% {
        height: 0;
    }
    100% {
        height: 100%;
    }
}

.text-box h2 {
    font-weight: 600;
}

.left-container-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #404040;
    right: -15px;
}

.last-arrow {
    top: 100px;
}
.timeline .text-box p{
    color: goldenrod;
}

.right-container-arrow {
    height: 0;
    width: 0;
    position: absolute;
    top: 28px;
    z-index: 1;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #404040;
    left: -15px;
}


@media (max-width: 768px)  {
    .timeline {
        margin: 50px auto;
    }

    .timeline::after {
        margin: 15px auto;
        left: 25px;
    }
    .hww_container {
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;
    }
    .text-box {
        font-size: 13px;
    }
    .right-container {
        left: 0;
    }
    .left-container img,
    .right-container img {
        left: 10px;
    }
    .left-container-arrow,
    .right-container-arrow {
        border-right: 15px solid #404040;
        border-left: 0;
        left: -15px;
    }
    .left-container .last-img {
        top: 80px;
        width: 60px;
        right: -30px;
        left: 0px;
    }
    .last-arrow {
        top: 80px;
    }
    .timeline .text-box p{
        color: goldenrod;
    }
    .how-we-work .title h1 {
        font-size: 30px;
    }
    @keyframes moveline {
        0% {
            height: 0;
        }
        100% {
            height: 90%;
        }
    }

}


@media (max-width: 450px)  {
    .timeline {
        margin: 50px auto;
    }

    .timeline::after {
        max-height: 87%;
        left: 25px;
    }
    .hww_container {
        width: 100%;
        padding-left: 80px;
        padding-right: 25px;
    }
    .text-box {
        font-size: 13px;
    }
    .right-container {
        left: 0;
    }
    .left-container img,
    .right-container img {
        left: 10px;
    }
    .left-container-arrow,
    .right-container-arrow {
        border-right: 15px solid #404040;
        border-left: 0;
        left: -15px;
    }
    .left-container .last-img {
        top: 120px;
        width: 60px;
        right: -30px;
        left: 0px;
    }
    .last-arrow {
        top: 130px;
    }
    .timeline .text-box p{
        color: goldenrod;
    }
    .how-we-work .title h1 {
        font-size: 30px;
    }
    @keyframes moveline {
        0% {
            height: 0;
        }
        100% {
            height: 100%;
        }
    }

}
