/* General styles */
body {
    font-family: 'Playfair Display', serif;
    background: #e2dcd4;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Page container styles */
.our-service-page .container {
    max-width: 1000px;
    margin: auto;
    padding: 40px;
}

/* Title styles */
.our-service .title {
    text-align: center;
    font-size: 25px;
    padding: 0!important;
    font-weight: bold;
    letter-spacing: 2px;
    background: linear-gradient(20deg, #DAA520, black, #DAA520, #DAA520, #DAA520);
    -webkit-background-clip: text;
    color: transparent;
    background-size: 200%;
}
.our-service {
    font-size: 18px;
}

.our-service-section {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.our-service-free-offer{
    margin-top: 20px;
    font-size: 12px;
}
/* List styles */
.our-service-list {
    list-style-type: circle; /* Use circle bullets */
    padding-left: 10px;
    margin-top: 10px;
}
.service-gradient{
    letter-spacing: 1px;
    font-family: "Montserrat", sans-serif;
    text-align:center;

    font-weight: 500!important;
    background: linear-gradient(7deg,#DAA520, black, #DAA520, black, #DAA520, black)!important;
    -webkit-background-clip: text!important;
    color: transparent!important;
}

.our-service-text {
    margin-top: 20px !important;
    color: #404040;
    flex: 1;
}

.our-service-text-before-list{
    font-size: 17px;
}

.our-service-page .btn {
    width: 250px;
    height: 80px;
    text-transform: uppercase;
    color: #404040;
    cursor: pointer;
    font-size: 14px;
    font-weight: 200;
    line-height: 3;
    outline: transparent;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s ease-in-out;
    user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    letter-spacing: 2px;
}

.our-service-page .request_free_service:not([disabled]):focus {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(214, 204, 194, 0.5), .125rem .125rem 1rem rgba(40,40,40, 0.5);
}
.our-service-page .request_free_service:not([disabled]):hover {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(214, 204, 194, 0.5), .125rem .125rem 1rem rgba(40,40,40, 0.5);
}
@keyframes border-rotate {
    0%, 25%, 50%, 75%, 100% {
        border-image: linear-gradient(180deg, black, #ffd700, black) 1;
    }
}
.our-service-page .request_free_service {
    border: 1px solid transparent;
    border-image: linear-gradient(90deg, black, #ffd700, black) 1;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: border-rotate 20s linear infinite;
}
@media (max-width: 768px) {
    .our-service .title {
        font-size: 30px;
        margin: 20px;
    }
    .impact-item {
        margin: 10px 0;
        height: auto;
    }
    .our-service-page .btn {
        width: 180px;
        height: 60px;
        font-size: 14px;
    }
}
@media (max-width: 480px) {

    .our-service p {
        font-size: 12px;
        font-weight: 500;
    }
    .our-service h3 {
        font-size: 12px;
    }
    .our-service-text {
        font-weight: 500;
        margin-top: 10px;
        font-size: 10px;
    }

    .our-service .title {
        font-size: 20px;
        margin: 15px;
    }
    .our-service-text {
        margin: 5px 0;
        height: auto;
    }
    .our-service-page .btn {
        width: 200px;
        font-size: 8px;

    }

}

