.text-my-own-color {
    color: #000 !important;
    padding-right: 0!important;
    padding-left: 0!important;
}

.navbar {
    font-size: 17px;
    border-bottom: 1px solid #d99f5e;
    border-image: linear-gradient(to right, black, #ffd700) 1 0%;
}

.text-my-own-color:hover,
.text-my-own-color:active {
    color: black !important;
    font-size: 100%;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
    0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

section {
    display: grid;
    place-items: center;
}

#navbar {
    transition: top 0.3s;
}

.nav-link {
    color: #000;
}

.nav-link:hover {
    color: #555;
}

.navbar-nav .nav-link {
    color: #000 !important;
}

.navbar-nav .nav-link.active {
    color: #000 !important;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
    color: #000 !important;
}

.navbar-toggler[aria-expanded="true"] {
    background-color: #e2dcd4;
    color: #000 !important;
}

#navbar {
    background-color: #e2dcd4;
    color: #000;
}

.flags-menu {
    display: block;
}

.lang_icon {
    display: block;
    width: 30px;
}

.lang_icon:hover {
    width: 30px;
    text-shadow: none;
}

.MyCustomButton:hover {
    background-color: inherit !important;
}

.navbar .get_started_now {
    color: #404040;
    cursor: pointer;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, black, #ffd700, black) 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar .get_started_now:not([disabled]):focus {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(214, 204, 194, 0.5), .125rem .125rem 1rem rgba(40, 40, 40, 0.5);

}
.client-reviews-link {
    position: relative; /* Устанавливаем контейнер для управления содержимым */
    display: inline-block; /* Гарантируем, что содержимое остаётся в одной строке */
}

.stars-image {
    position: absolute; /* Абсолютное позиционирование звёзд */
    bottom: -10px; /* Поднимаем звёзды ближе к тексту, чтобы не менять высоту хедера */
    left: 50%; /* Центруем звёзды относительно текста */
    transform: translateX(-50%); /* Выравниваем по центру */
    pointer-events: none; /* Отключаем возможность клика по картинке */
}
.navbar .get_started_now:hover {
    text-shadow: none;
    text-decoration: none;
    color: #404040;
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(214, 204, 194, 0.5), .125rem .125rem 1rem rgba(40, 40, 40, 0.5);
}

.navbar .for-developers {
    display: flex;
    justify-content: flex-end;
    padding-left: 1rem;
}

.nav-separator {
    display: block;
    border-left: 1px solid black;
    margin-left: 1rem;
    padding-left: 1rem;
}
.collapse{
    display: flex;
    justify-content: end;
}
@media (max-width: 992px) {
    .nav-separator {
        display: none;
        border: none;
        margin: 0;
        padding: 0;
    }
    .nav{
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
    .collapse{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: start;
    }
    .nav-item.nav-link {
        padding: 10px 0 0px 0!important;
    }

    .stars-image {
            display: none; /* Убираем звёзды на мобильных устройствах */
        }
}