.why-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.why-header h1 {
    margin: 0;
    padding: 0;
    font-size: 3em;
}

.why-container {
    height: 100vh; /* Полная высота экрана */
    padding: 80px 0 0 0;
    margin: 20px 0;
}

.why-page {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr; /* Три колонки: боковые узкие, центральная широкая */
    grid-template-rows: 280px 280px; /* Две строки */
    align-items: center; /* Выравнивание содержимого по центру по вертикали */
    gap: 20px; /* Отступы между элементами сетки */
    padding: 0 150px; /* Отступ от краев страницы */
}

.boxes {
    display: contents; /* Используем содержимое как часть внешней сетки */
}

.why-page .box {
    animation-delay: 2s;
    height: 280px;
    width: 270px;
    display: block;
    align-items: center;
    justify-content: center;
    border: 3px solid transparent;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
    background-color: #404040;
    color: white;
    font-size: 15px;
    margin: auto;
}

.why-page .box h2 {
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    /*font-weight: bold;*/
    font-size: 16px;
    color: #404040;
}

.why-page .headline {
    padding: 10px 3px 3px 3px;
    background-color: white;
    border-radius: 10px;
    /*border: 4px solid white;*/
}

.why-page .box p {
    text-align: center;
    padding: 5px 20px;
}

.ikigai-symbol {
    margin: auto;
    grid-column: 2; /* Центральная колонка */
    grid-row: 1 / span 2; /* Распространяется на две строки */
}

.why-page .left-top {
    background-color: #c05541;
}

.why-page .left-bottom {
    background-color: #576535;
}

.why-page .right-top {
    background-color: #271a18;
}

.why-page .right-bottom {
    background-color: #2b6f7c;
}

/* Медиазапросы для адаптивного дизайна */
@media (max-width: 1024px) {
    .why-container{
        padding: 20px 0;
        margin: 20px 0;
        height: 200vh;
    }
    .why-page {
        height: 100vh;
        grid-template-columns: 1fr 1fr; /* Две колонки на средних экранах */
        grid-template-rows: auto; /* Автоматическая высота строк */
        padding: 0 50px; /* Уменьшение отступа от краев страницы */
    }

    .ikigai-symbol {
        grid-column: span 2; /* Центральная колонка на два столбца */
        grid-row: auto; /* Автоматическая высота */
    }

    .why-page .box {
        color: white;
        height: 250px; /* Автоматическая высота */
        width: 300px; /* Ширина 100% от родительского контейнера */
    }
}

@media (max-width: 768px) {
    .why-container{
        padding: 20px 0;
        margin: 20px 0;
        height: 250vh;
    }
    .why-page {
        align-items: center;
        grid-template-columns: 1fr; /* Одна колонка на маленьких экранах */
        grid-template-rows: auto; /* Автоматическая высота строк */
        padding: 0 20px; /* Уменьшение отступа от краев страницы */
    }

    .ikigai-symbol {
        grid-column: 1; /* Центральная колонка */
        grid-row: auto; /* Автоматическая высота */
    }

    .why-page .box {
        color: white;
        height: 300px; /* Автоматическая высота */
        width: 250px;
        margin: 20px!important;
    }
    .why-page .box p{
        color: white;
    }
    .why-page .boxes{
        display: flex;
        flex-direction: column;

        align-items: center;
    }
}
