/* Общий контейнер */
.testimonial-container {
    max-width: 100%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center; /* Центрирование содержимого по горизонтали */
    justify-content: center;
    padding: 1.5rem 1rem;
    box-sizing: border-box;
    overflow: hidden;
}

/* Слайдер */
.slider-container {
    max-width: 100%;
    padding: 0 1rem;
    overflow: hidden;
    box-sizing: border-box;
}

/* Слайды */
.splide__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
    gap: 0.8rem;
    background-color: #e2dcd4;
    border-radius: 0.8rem;
    box-sizing: border-box;
    width: 100%;
}

/* Изображения */
.review-img {
    max-width: 100px;
    max-height: 100px;
    object-fit: contain;
    margin: 10px auto;
}

/* Текст отзыва */
.slider-container .content .text {
    max-width: 90%;
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 0 auto;
    color: #484646;
    text-align: center;
}

/* Звёзды */
.slider-container .content .rating {
    display: flex;
    justify-content: center;
    gap: 0.2rem;
    font-size: 1.2rem;
}

.slider-container .content .star {
    color: #DAA520 !important;
}

/* Имя пользователя */
.slider-container .content .user {
    font-weight: 600;
    margin-top: 0.5rem;
}

/* Должность пользователя */
.slider-container .content .position {
    font-size: 0.85rem;
    color: #6c757d;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
    font-style: italic;
}

/* Обёртка кнопки */
.testimonial-container .trustpilot {
    height: auto;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 10px 0px 10px;
}

/* Логотип */
.testimonial-container .trustpilot_logo img {
    width: 90px;
    height: auto;
}

/* Пагинация */
.splide__pagination__page {
    height: 8px;
    width: 8px;
    background-color: #d3d3d3;
}

.splide__pagination__page.is-active {
    background-color: #DAA520;
}

/* Стрелки */
.splide__arrow {
    font-size: 2rem;
    color: #505050;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

/* Убираем стрелки на мобильных устройствах */
@media (max-width: 576px) {
    .splide__arrow {
        display: none !important;
    }
}

/* Планшеты (576px до 768px) */
@media (min-width: 576px) and (max-width: 768px) {
    .btn {
        width: 165px;
        font-size: 15px;
    }

    .trustpilot_logo img {
        width: 80px;
    }
}
