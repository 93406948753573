/* General styles */
body {
    font-family: 'Playfair Display', serif;
    background: #e2dcd4;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Flip container styles */
.flip-container, .front, .back {
    width: 70px;
    height: 70px;
}
.flipper {
    position: relative;
    transition: 3s;
    transform-style: preserve-3d;
}
.flip-container.flipped .flipper {
    transform: rotateY(180deg);
    transition-delay: 3s;
}
.flip-container.flipped {
    transition: transform 4s;
}
.front, .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.front {
    z-index: 2;
}
.back {
    transform: rotateY(180deg);
}
.make_it_happen {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Page container styles */
.for-developers-page .container {
    max-width: 1000px;
    margin: auto;
    padding: 40px;
}

/* Title styles */
.for-developers .title {
    margin: 50px;
    text-align: center;
    font-size: 40px;
    padding: 0!important;
    font-weight: bold;
    letter-spacing: 2px;
    background: linear-gradient(20deg, #DAA520, black, #DAA520, #DAA520, #DAA520);
    -webkit-background-clip: text;
    color: transparent;
    background-size: 200%;
}
.for-developers {
    font-size: 18px;
}
/* Impact section styles */
.impact-section {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.impact-section .btn {
    display: block;
    align-self: center;
}
.impact-item {
    height: 80px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
}
.impact-item .first-letter {
    font-size: 27px;
    font-weight: bold;
}
.impact-text {
    margin-top: 20px !important;
    color: #404040;
    flex: 1;
    font-size: 15px;
}

.impact-text h3 {
    background: linear-gradient(5deg,#DAA520, black, #DAA520, black);
    -webkit-background-clip: text;
    color: transparent;
    padding: 0;
    margin: 0;
    font-size: 17px;
    text-align: left;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 500;
}

/* Button styles */
.for-developers-page .btn {
    width: 250px;
    height: 80px;
    text-transform: uppercase;
    color: #404040;
    cursor: pointer;
    font-size: 15px;
    font-weight: 200;
    line-height: 1.5;
    outline: transparent;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s ease-in-out;
    user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    letter-spacing: 2px;
}

.for-developers-page .make_it_happen:not([disabled]):focus {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(214, 204, 194, 0.5), .125rem .125rem 1rem rgba(40,40,40, 0.5);
}
.for-developers-page .make_it_happen:not([disabled]):hover {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(214, 204, 194, 0.5), .125rem .125rem 1rem rgba(40,40,40, 0.5);
}
@keyframes border-rotate {
    0%, 25%, 50%, 75%, 100% {
        border-image: linear-gradient(180deg, black, #ffd700, black) 1;
    }
}
.for-developers-page .make_it_happen {
    border: 1px solid transparent;
    border-image: linear-gradient(90deg, black, #ffd700, black) 1;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: border-rotate 20s linear infinite;
}
.impact-gradient{
    background: linear-gradient(5deg,#DAA520, black, #DAA520, black)!important;
    -webkit-background-clip: text!important;
    color: transparent!important;
}
/* Responsive styles */
@media (max-width: 768px) {
    .for-developers .title {
        font-size: 30px;
        margin: 20px;
    }
    .impact-item {
        margin: 10px 0;
        height: auto;
    }
    .for-developers-page .btn {
        width: 180px;
        height: 60px;
        font-size: 14px;
    }
}
@media (max-width: 480px) {

    .for-developers p{
        font-size: 12px;
        font-weight: 500;
    }
    .impact-text h3 {
        font-size: 10px;
    }
    .impact-text {
        font-weight: 500;
        margin-top: 10px;
        font-size: 8px;
    }

    .for-developers .title {
        font-size: 25px;
        margin: 15px;
    }
    .impact-item .first-letter {
        font-size: 17px;
        font-weight: normal;
    }
    .impact-item {
        margin: 5px 0;
        height: auto;
    }
    .for-developers-page .btn {
        width: 215px;
        height: 50px;
        padding: 0;
        font-size: 12px;
    }

}
