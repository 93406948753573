footer {
    text-align: center;
    padding-top: 120px;
}

.footer-top {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
}

.footer-bottom {
    padding-top: 40px;
    padding-bottom: 40px;
}

h5 {
    display: flex;
    justify-content: left;
}

footer li,
footer p,
footer a {
    cursor: pointer;
    color: rgba(255,255,255,0.7);
    display: flex;
    justify-content: left;
    text-decoration: none;  /* Add this line to remove underline */
}

footer a:hover {
    color: rgba(255,255,255,0.7);
    text-decoration: none;
    font-weight: bold;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer .line {
    width: 80px;
    height: 4px;
    background-color:#d6ccc2;
    margin-top: 12px;
    margin-bottom: 24px;
}

.social-icons a {
    margin: 5px;
    margin-top: 20px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.1);
    border-radius: 100px;
}

.social-icons a:hover {
    background-color:#d6ccc2;
    color: black;
    text-decoration: none;
}

.name {
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
