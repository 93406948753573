body {
  background-color: #e2dcd4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p{
  line-height: 150%;
}
@media (max-width: 768px) {
  p {
    color:black;
  }
  body{
    overflow-x: hidden;
  }

}
