.why-page {
    height: 100vh;
    display: grid;
}

header {
    text-align: center;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

header h1 {
    font-size: 3em;
}

.iki {
    font-family: sans-serif;
    display: grid;
    grid-template-rows: 4fr 1fr 2fr 4fr 2fr 1fr 4fr;
    grid-template-columns: 4fr 1fr 2fr 4fr 2fr 1fr 4fr;
    width: 40vw;
    height: 40vw;
    max-width: 400px;
    max-height: 400px;
    margin: auto;
}

.iki p {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: bold;
}

.iki section {
    z-index: 1;
    border-radius: 50%;
    border: 1px solid #404040;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center; /* Центрирование содержимого по вертикали */
    text-align: center;
}

.c-m-dev, .performance, .cost, .commitments, .ikigai {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(226, 220, 212, 0.5);
}

.c-m-dev {
    grid-area: 5 / 2 / 1 / 7;
    align-items: flex-start;
    margin: 50px;
    padding: 10px;
}

.c-m-dev p {
    margin: 0 0 120px 0;
}

.performance {
    grid-area: 2 / 4 / 7 / 8;
}

.performance p {
    margin: 0 -140px 0 0;
}

.cost {
    grid-area: 4 / 2 / 8 / 7;
    align-items: flex-end;
}

.cost p {
    margin: 0 0 -120px 0;
}

.commitments {
    grid-area: 2 / 5 / 7 / 1;
    justify-content: flex-start;
}

.commitments p {
    margin: 0 140px 0 0;
}

.ikigai {
    background: linear-gradient(20deg, black, #DAA520, black);
    -webkit-background-clip: text;
    color: transparent;
    background-size: 200%;
    animation: gradient 5s ease infinite;
    grid-area: 4 / 4 / 5 / 5;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 !important;
    padding: 0 !important;
    cursor: pointer;
}
.ikigai:hover {
    text-shadow: 1px 1px 0px rgba(0,0,0, 0.1) ;
}

/* Медиазапросы для адаптивного дизайна */
@media (max-width: 1024px) {
    .c-m-dev p {
        margin: 0 0 120px 0;
    }
    .cost p {
        margin: 0 0 -120px 0;
    }
    .performance p {
        margin: 0 -130px 0 0;
    }
    .commitments p {
        margin: 0 130px 0 0;
    }
    .iki {
        width: 60vw; /* Уменьшение ширины */
        height: 60vw; /* Уменьшение высоты */
    }

    .c-m-dev, .performance, .cost, .commitments {
        margin: 20px; /* Уменьшаем внешние отступы */
    }

    .iki p {
        font-size: 10px; /* Уменьшение размера шрифта */
    }

    .ikigai {
        font-size: 9px; /* Уменьшение размера шрифта для среднего круга */
    }
}

@media (max-width: 768px) {
    .iki {
        width: 80vw; /* Еще большее уменьшение ширины */
        height: 80vw; /* Еще большее уменьшение высоты */
    }

    .c-m-dev, .performance, .cost, .commitments {
        margin: 10px; /* Уменьшаем внешние отступы */
    }

    .iki p {
        font-size: 9px; /* Еще большее уменьшение размера шрифта */
    }

    .ikigai {
        font-size: 7px; /* Еще большее уменьшение размера шрифта для среднего круга */
    }
    .c-m-dev p {
        margin: -120px 0 0 0;
    }
    .cost p {
        margin: 120px 0 0 0;
    }
    .performance p {
        margin: 0 -130px 0 0;
    }
    .commitments p {
        margin: 0 130px 0 0;
    }
}

@media (max-width: 480px) {
    header h1 {
        font-size: 2em; /* Уменьшение размера шрифта заголовка */
    }

    .iki {
        width: 90vw; /* Еще большее уменьшение ширины */
        height: 90vw; /* Еще большее уменьшение высоты */
    }

    .c-m-dev, .performance, .cost, .commitments {
        margin: 5px; /* Уменьшаем внешние отступы */
    }


    .iki p {
        font-size: 7px; /* Еще большее уменьшение размера шрифта */
    }
    .c-m-dev p {
        margin: -80px 0 0 0;
    }
    .cost p {
        margin: 80px 0 0 0;
    }
    .performance p {
        margin: 0 -100px 0 0;
    }
    .commitments p {
        margin: 0 100px 0 0;
    }
    .ikigai {
        font-size: 7px; /* Еще большее уменьшение размера шрифта для среднего круга */
    }

}
