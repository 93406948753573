.App{
    background-color: #e2dcd4 ;
    color:	#404040;
    font-family: "Montserrat", sans-serif;
    /*font-family: 'Playfair Display', serif;*/
    font-weight: lighter;
    overflow-x: hidden;
}
.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6 {
    font-weight: lighter;
    color: 	#404040;
    text-align: center;

}
a{
    text-decoration: none;
}
@media (max-width: 768px) {
    h2 {
        font-size: 28px;
    }

}