/* Основные стили контейнера */
.main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Playfair Display', serif;
}

.name h1 {
    margin: 100px 0 0 0;
    padding: 0 !important;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 2px;
    background: linear-gradient(20deg, black, #DAA520, black);
    -webkit-background-clip: text;
    color: transparent;
    background-size: 200%;
    animation: gradient 20s ease infinite, fadeInDown 2s forwards;
}

/* Стили анимации градиента */
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.name p {
    padding: 20px;
    text-align: center;
    font-size: 22px;
    color: #404040;
    letter-spacing: 1px;
    opacity: 0;
    animation: fadeIn 2s forwards 1s;
}

/* Стили анимации появления */
@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

/* Стили логотипа */
.logo {
    margin: 0 10px 0 0;
    width: 50px;
    height: 50px;
}

/* Стили кнопки */
.main .btn {
    width: 250px;
    height: 60px;
    text-transform: uppercase;
    color: #404040;
    cursor: pointer;
    font-size: 15px;
    font-weight: 200;
    line-height: 1.5;
    outline: transparent;
    padding: 3px 7px !important;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s ease-in-out;
    user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    letter-spacing: 2px;
}

.main .make_it_happen {
    height: 60px;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, black, #ffd700, black) 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Стили флип-контейнера */
.flip-container,
.front,
.back {
    width: 50px;
    height: 50px;
}

.flipper {
    transition: transform 0.6s;;
    transform-style: preserve-3d;
    position: relative;
}

.front,
.back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.front {
    z-index: 2;
}

.back {
    transform: rotateY(180deg);
}

/* Анимация флип-контейнера */
/*.flip-container.flipped .flipper {*/
/*    transform: rotateY(180deg);*/
/*}*/
.flip-on-load {
    animation: flipLogo 4s forwards;
}
@keyframes flipLogo {
    from {

        transform: rotateY(0deg);
    }
    to {

        transform: rotateY(180deg);
    }
}
/*.flip-container.flipped {*/
/*    transition: transform 4s;*/
/*}*/

.main .make_it_happen:not([disabled]):focus {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(214, 204, 194, 0.5), .125rem .125rem 1rem rgba(40,40,40, 0.5);
}

.main .make_it_happen:not([disabled]):hover .flip-container .flipped {
    transform: rotateY(0deg);
    transition-duration: 4s;
    -webkit-transform: rotateY(180deg);
}

.main .make_it_happen:not([disabled]):hover {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(214, 204, 194, 0.5), .125rem .125rem 1rem rgba(40,40,40, 0.5);
}
/* Медиа-запросы для адаптивного дизайна */
@media only screen and (max-width: 1200px) {
    .name h1 {
        font-size: 45px;
    }
    .name p {
        font-size: 25px;
    }
}

@media only screen and (max-width: 992px) {
    .name h1 {
        font-size: 30px;
    }
    .name p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .main .make_it_happen {
        height: 50px;
    }
    .name h1 {
        letter-spacing: 2px;
        font-size: 20px;
    }
    .name p {
        letter-spacing: 1px;
        font-size: 18px;
    }
    .main .btn {
        width: 220px;
        height: 50px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 576px) {
    .main .make_it_happen {
        height: 45px;
    }
    .name h1 {
        margin:0;
        font-size: 20px;
    }
    .name p {
        letter-spacing: 1px;
        font-size: 16px;
    }
    .main .btn {
        width: 190px;
        height: 45px;
        font-size: 10px;
    }
    .logo {
        width: 40px;
        height: 40px;
    }
}
