.calendly-container {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    height: 100vh;
    overflow-y: auto;
    padding: 15px;
}

.calendly-inline-widget {
    min-height: 100%;
    height: 700px;
    max-height: none;
}

@media (max-width: 1024px) {
    .calendly-container {
        padding: 15px;
    }

    .calendly-inline-widget {
        height: 600px !important;
    }
}

@media (max-width: 768px) {
    .calendly-container {
        padding: 10px;
    }

    .calendly-inline-widget {
        height: 500px !important;
    }
}

@media (max-width: 480px) {
    .calendly-container {
        padding: 5px;
    }

    .calendly-inline-widget {
        margin-top: 100px;
        height: 1000px !important;
    }
}
