/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    top: 50px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 20px;
    border-radius: 8px;
}

.close-button {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 100px;
    right: 150px;
    background: rgba(255, 255, 255, 0.5);
    color: black;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 16px;
    line-height: 1;
    z-index: 1000;
}
@media (max-width: 900px) {
    .close-button {
        top:120px;
        right: 120px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        padding:2px 4px;
    }
    .modal-content {
        /*top: 200px;*/
        padding: 0px;
    }
}
@media (max-width: 768px) {
    .close-button {
        top:120px;
        right: 15px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        padding:2px 4px;
    }
    .modal-content {
        /*top: 200px;*/
        padding: 0px;
    }
}
